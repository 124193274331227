<template>
  <div>
    <b-row>
      <b-col
        xl="4"
        lg="5"
        md="6"
        sm="12"
      >
        <b-card
          v-if="user"
        >
          <b-form>
            <validation-observer
              ref="userProfileRules"
              v-slot="{ invalid }"
            >
              <b-form-group
                label="Nombre"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Contraseña actual"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.name"
                    type="text"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Teléfono"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Teléfono"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.phone"
                    type="text"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="success"
                :disabled="saving || invalid"
                @click="onSaveClick"
              >
                Guardar
                <b-spinner
                  v-if="saving"
                  small
                />
              </b-button>
            </validation-observer>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import user from '@/services/user'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
  },
  data() {
    return {
      user: null,
      saving: false,
      required,
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.services.userInfo
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(value) {
        if (value) {
          this.user = { name: this.userInfo.name, phone: this.userInfo.phone }
        }
      },
    },
  },
  methods: {
    onSaveClick() {
      this.$refs.userProfileRules.validate().then(async success => {
        if (success) {
          this.saving = true
          try {
            await user.updateProfileInfo(this.user)
            this.$store.commit('services/UPDATE_USER_NAME', this.user.name)
            this.$store.commit('services/UPDATE_USER_PHONE', this.user.phone)
            this.showNotification('Listo', 'La información se guardó exitosamente', 'CheckIcon', 'success')
          } catch (err) {
            this.showNotification('Error al actualizar información del perfil', err.message, 'AlertCircleIcon', 'danger')
          } finally {
            this.saving = false
          }
        }
      })
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
