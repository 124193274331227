import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

export default {
  async updateProfileInfo(newUserInfo) {
    const user = firebase.auth().currentUser
    const updates = {}
    updates[`/users/${user.uid}/name`] = newUserInfo.name
    updates[`/users/${user.uid}/phone`] = newUserInfo.phone
    const result = await firebase.database().ref().update(updates)
    return result
  },
}
